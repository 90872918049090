<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img src="../../assets/image/takeCare-img/baoguan_bg_detail.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img
        :src="$IMG_URL + $store.state.webData.top_image.collection_detail_image"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
    </div>

    <div class="_content">
      <div class="_auto">
        <div class="_bread">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/takeCare' }"
              >保管</el-breadcrumb-item
            >
            <el-breadcrumb-item>藏品</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="_nr">
          <h4>{{ detail.collection_name }}</h4>

          <div class="_content">
            <div class="_js">
              <p>时代：{{ detail.year_date }}</p>

              <p>质地：{{ detail.texture }}</p>
              <p>级别：{{ detail.level }}</p>

              <!-- <p class="_impact">入藏时间：{{ detail.year_date }}</p> -->
              <p>收藏单位：{{ detail.collection_unit }}</p>
            </div>

            <div class="_html" ref="myHtml">
              <!-- <img :src="$IMG_URL + detail.image" alt="八路军驻洛办事处纪念馆-官方网站" class="_htmlImg" ref="myImage" :style="styleObj"> -->
              <iframe
                ref="myIframe"
                id="myIframe"
                :src="src"
                frameborder="0"
              ></iframe>

              <img
                @click="all"
                class="_all"
                src="../../assets/image/takeCare-img/all.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <mainFooter></mainFooter>
  </div>
</template>

<script>
import { collectionDetail } from "@/api/getData";
export default {
  data() {
    return {
      src: "",
      tdk: {
        title: "详情",
        keywords: "",
        desc: "",
      },
      styleObj: {
        width: "auto",
      },
      detail: {},
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  // metaInfo() {

  //     return {
  //         title: this.tdk.title,
  //         meta: [
  //             { name: "keywords", content: this.tdk.keywords },
  //             { name: "description", content: this.tdk.desc },
  //         ]
  //     }
  // },
  async created() {
    // await this.getData()
  },
  async mounted() {
    await this.getData();

    // const imgWidth = this.$refs.myImage.width

    // const boxWidth = this.$refs.myHtml.offsetWidth

    // console.log(imgWidth)

    // if (imgWidth > boxWidth) {
    //     // this.$refs.myImage.width = boxWidth
    //     this.styleObj.width = boxWidth + "px"
    // } else {
    //     this.styleObj.width = 'auto'
    // }
  },
  methods: {
    launchFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        //不同浏览器之间需要添加前缀
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      }
    },

    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },

    all() {
      this.launchFullscreen(this.$refs.myIframe);
    },
    async getData() {
      const params = {
        id: this.$route.query.id,
      };

      await collectionDetail(params).then((res) => {
        console.log(res);
        this.detail = res.data;
        this.src = res.data["3d_url"];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fdfaf6;
}

._banner {
  width: 100%;

  height: 700px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

._content {
  width: 100%;
  margin-bottom: 108px;

  ._auto {
    width: 75%;
    margin: 0 auto;

    ._bread {
      margin-top: 24px;
    }

    ._nr {
      margin-top: 66px;

      h4 {
        font-size: 30px;
      }

      ._content {
        display: flex;
      }

      ._js {
        width: 40%;
        margin-top: 24px;
        // padding-left: 15px;

        p {
          margin: 15px 0;
          font-size: 20px;
          color: #999;
        }
      }

      ._html {
        width: 800px;
        height: 500px;

        // margin-top: 60px;
        text-align: center;
        position: relative;

        #myIframe {
          width: 800px;
          height: 500px;
        }

        ._all {
          position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep .el-breadcrumb__inner {
  color: #969696 !important;
  font-weight: normal !important;
}

@include respondTo("phone") {
  ._banner {
    height: 180px;
    margin-top: 70px;
  }
  ._content {
    margin-bottom: 40px;
    ._auto {
      padding: 0 8px;
      ._bread {
        margin-top: 20px;
      }
      ._nr {
        margin-top: 20px;
        h4 {
          font-size: 22px;
        }

        ._content {
          display: block;
          ._js{
            width: 100%;
            margin-top: 20px;
            p{
                margin: 10px 0;
            }
          }
          ._html{
            width: 100%;
            height: 400px;
            #myIframe{
                width: 100%;
                height: 100%;
            }

          }
        }
      }
    }
  }
}
</style>